// SPDX-FileCopyrightText: OpenTalk GmbH <mail@opentalk.eu>
//
// SPDX-License-Identifier: EUPL-1.2
import { LocalUserChoices } from '@livekit/components-core';
import { usePersistentUserChoices } from '@livekit/components-react';
import { createContext, useContext } from 'react';

interface UserChoicesValues {
  userChoices: LocalUserChoices;
  saveAudioInputEnabled: (isEnabled: boolean) => void;
  saveVideoInputEnabled: (isEnabled: boolean) => void;
  saveAudioInputDeviceId: (deviceId: string) => void;
  saveVideoInputDeviceId: (deviceId: string) => void;
  saveUsername: (username: string) => void;
}

const UserChoicesContext = createContext<UserChoicesValues | undefined>(undefined);

const UserChoicesProvider = ({ children }: { children: React.ReactNode }) => {
  const userChoicesState = usePersistentUserChoices({
    defaults: {
      audioEnabled: false,
      videoEnabled: false,
    },
  });

  return <UserChoicesContext.Provider value={userChoicesState}>{children}</UserChoicesContext.Provider>;
};

export const useUserChoices = () => {
  return useContext(UserChoicesContext);
};

export default UserChoicesProvider;
