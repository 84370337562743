// SPDX-FileCopyrightText: OpenTalk GmbH <mail@opentalk.eu>
//
// SPDX-License-Identifier: EUPL-1.2
import { useMediaDeviceSelect } from '@livekit/components-react';
import { styled } from '@mui/material';
import { LocalAudioTrack } from 'livekit-client';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { MicOffIcon, MicOnIcon } from '../../../assets/icons';
import { useUserChoices } from '../../../provider/UserChoicesProvider';
import { ToolbarButtonIds } from '../Toolbar';
import AudioIndicator from './AudioIndicator';
import AudioMenu from './AudioMenu';
import ToolbarButton from './ToolbarButton';

const MicOnStyled = styled(MicOnIcon)({
  zIndex: 1,
});

interface AudioButtonProps {
  track?: LocalAudioTrack;
  isLobby?: boolean;
  microphoneEnabled: boolean;
}

const AudioButton = ({ track, isLobby = false, microphoneEnabled }: AudioButtonProps) => {
  const choices = useUserChoices();
  const { t } = useTranslation();

  const [permissionDenied, setPermissionDenied] = useState<boolean | undefined>(undefined);
  const [showMenu, setShowMenu] = useState(false);
  const menuRef = useRef<HTMLDivElement>(null);

  const closeMenu = () => setShowMenu(false);
  const openMenu = () => setShowMenu(true);

  const handleError = useCallback((e: Error) => {
    setPermissionDenied(true);
    console.error('Error creaing audio track: ', e);
  }, []);

  const { devices } = useMediaDeviceSelect({
    kind: 'audioinput',
    requestPermissions: choices?.userChoices.audioEnabled || false,
    onError: handleError,
  });
  const filteredDevices = devices.filter((device) => device.deviceId !== '');

  useEffect(() => {
    if (choices?.userChoices.audioDeviceId === '' && filteredDevices[0]?.deviceId) {
      choices.saveAudioInputDeviceId(filteredDevices[0].deviceId);
    }
  }, [filteredDevices, choices]);

  const indicator = useMemo(
    () =>
      microphoneEnabled ? (
        <>
          <AudioIndicator shape="circle" track={track} />
          <MicOnStyled data-testid="toolbarAudioButtonOn" />
        </>
      ) : (
        <MicOffIcon data-testid="toolbarAudioButtonOff" />
      ),
    [microphoneEnabled, track]
  );

  const tooltipText = () => {
    if (permissionDenied) {
      return t('device-permission-denied');
    }
    if (microphoneEnabled) {
      return t('toolbar-button-audio-turn-off-tooltip-title');
    }
    return t('toolbar-button-audio-turn-on-tooltip-title');
  };

  return (
    <div ref={menuRef}>
      <ToolbarButton
        tooltipTitle={tooltipText()}
        onClick={() => choices?.saveAudioInputEnabled?.(!microphoneEnabled)}
        hasContext
        contextDisabled={devices.length === 0}
        disabled={devices.length === 0}
        openMenu={openMenu}
        active={microphoneEnabled}
        isLobby={isLobby}
        data-testid="toolbarAudioButton"
        contextTitle={t('toolbar-button-audio-context-title')}
        contextMenuId="audio-context-menu"
        contextMenuExpanded={showMenu}
        id={ToolbarButtonIds.Audio}
      >
        {indicator}
      </ToolbarButton>
      <AudioMenu
        open={showMenu}
        onClose={closeMenu}
        anchorEl={menuRef.current}
        permissionDenied={permissionDenied || false}
        microphoneEnabled={microphoneEnabled}
      />
    </div>
  );
};

export default AudioButton;
