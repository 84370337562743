// SPDX-FileCopyrightText: OpenTalk GmbH <mail@opentalk.eu>
//
// SPDX-License-Identifier: EUPL-1.2
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { Room } from 'livekit-client';

import { RootState } from '..';

let livekitRoom: Room | undefined;

export const setLivekitRoom = (room: Room | undefined) => {
  livekitRoom = room;
};

export const getLivekitRoom = (): Room | undefined => {
  return livekitRoom;
};

interface State {
  livekitUnavailable: boolean;
}

const initialState: State = {
  livekitUnavailable: false,
};

export const livekitSlice = createSlice({
  name: 'livekit',
  initialState,
  reducers: {
    setLivekitUnavailable: (state, { payload }: PayloadAction<boolean>) => {
      state.livekitUnavailable = payload;
    },
  },
});

export const { setLivekitUnavailable } = livekitSlice.actions;

export const selectLivekitUnavailable = (state: RootState) => state.livekit.livekitUnavailable;

export default livekitSlice.reducer;

// const onPageLeave = async () => {
//   await livekitRoom?.disconnect();
// };

// export const livekitMiddleware: Middleware =
//   ({ dispatch, getState }: { dispatch: AppDispatch; getState: () => RootState }) =>
//   (next) =>
//   (action) => {
//     // TODO: livekit - deconstructors?
//     if (isWeb()) {
//       window.addEventListener('beforeunload', onPageLeave);
//     }

//     livekitRoom?.on(RoomEvent.TrackPublished, (pub, participant) => {
//       if (pub.source === Track.Source.ScreenShare) {
//         dispatch(pinnedRemoteScreenshare(participant.identity as ParticipantId));
//       }
//     });

//     livekitRoom?.on(RoomEvent.TrackUnpublished, (pub, participant) => {
//       const state = getState();
//       if (pub.source === Track.Source.ScreenShare && participant.identity === state.ui.pinnedParticipantId) {
//         dispatch(pinnedParticipantIdSet(undefined));
//       }
//     });

//     // livekitRoom?.on(RoomEvent.Disconnected, (reason) => {
//     //   console.log('RoomEvent.Disconnected: ', reason);
//     // });

//     return next(action);
//   };
