// SPDX-FileCopyrightText: OpenTalk GmbH <mail@opentalk.eu>
//
// SPDX-License-Identifier: EUPL-1.2
import { useMediaDeviceSelect } from '@livekit/components-react';
import { useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { CameraOffIcon, CameraOnIcon } from '../../../assets/icons';
import { useUserChoices } from '../../../provider/UserChoicesProvider';
import { ToolbarButtonIds } from '../Toolbar';
import ToolbarButton from './ToolbarButton';
import VideoMenu from './VideoMenu';

interface VideoButtonProps {
  isLobby?: boolean;
  videoEnabled: boolean;
}

const VideoButton = ({ isLobby = false, videoEnabled }: VideoButtonProps) => {
  const { t } = useTranslation();
  const choices = useUserChoices();

  const menuRef = useRef<HTMLDivElement>(null);
  const [showMenu, setShowMenu] = useState<boolean>(false);
  const [permissionDenied, setPermissionDenied] = useState<boolean | undefined>(undefined);

  const handleError = useCallback((e: Error) => {
    setPermissionDenied(true);
    console.error('Error creating video track: ', e);
  }, []);
  const { devices } = useMediaDeviceSelect({
    kind: 'videoinput',
    requestPermissions: choices?.userChoices.videoEnabled || false,
    onError: handleError,
  });

  const tooltipText = () => {
    if (permissionDenied) {
      return t('device-permission-denied');
    }
    if (videoEnabled) {
      return t('toolbar-button-video-turn-off-tooltip-title');
    }
    return t('toolbar-button-video-turn-on-tooltip-title');
  };

  return (
    <div ref={menuRef}>
      <ToolbarButton
        tooltipTitle={tooltipText()}
        onClick={() => choices?.saveVideoInputEnabled?.(!videoEnabled)}
        hasContext
        contextDisabled={devices.length === 0}
        contextTitle={t('toolbar-button-video-context-title')}
        contextMenuId="video-context-menu"
        contextMenuExpanded={showMenu}
        disabled={devices.length === 0}
        active={videoEnabled}
        openMenu={() => {
          setShowMenu(true);
        }}
        isLobby={isLobby}
        data-testid="toolbarVideoButton"
        id={ToolbarButtonIds.Video}
      >
        {videoEnabled ? <CameraOnIcon /> : <CameraOffIcon />}
      </ToolbarButton>
      <VideoMenu
        anchorEl={menuRef.current}
        onClose={() => {
          setShowMenu(false);
        }}
        open={showMenu}
        permissionDenied={permissionDenied || false}
        videoEnabled={videoEnabled}
        isLobby={isLobby}
      />
    </div>
  );
};

export default VideoButton;
