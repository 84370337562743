// SPDX-FileCopyrightText: OpenTalk GmbH <mail@opentalk.eu>
//
// SPDX-License-Identifier: EUPL-1.2
import { useTrackToggle } from '@livekit/components-react';
import { Track } from 'livekit-client';
import { useTranslation } from 'react-i18next';

import { ShareScreenOffIcon, ShareScreenOnIcon } from '../../../assets/icons';
import { useAppSelector } from '../../../hooks';
import { selectIsModerator, selectIsPresenter } from '../../../store/slices/userSlice';
import { ToolbarButtonIds } from '../Toolbar';
import ToolbarButton from './ToolbarButton';

const ShareScreenButton = () => {
  const { toggle, enabled, pending } = useTrackToggle({ source: Track.Source.ScreenShare });
  const { t } = useTranslation();
  const isPresenter = useAppSelector(selectIsPresenter);
  const isModerator = useAppSelector(selectIsModerator);

  const isModeratorOrPresenter = isModerator || isPresenter;

  const getToolTipTitle = () => {
    if (!isModeratorOrPresenter) {
      return t('toolbar-button-screen-share-tooltip-request-moderator-presenter-role');
    }
    // TODO: livekit - get real permission state
    if (pending) {
      return t('device-permission-denied');
    }
    if (enabled) {
      return t('toolbar-button-screen-share-turn-off-tooltip-title');
    }
    return t('toolbar-button-screen-share-turn-on-tooltip-title');
  };

  return (
    <ToolbarButton
      tooltipTitle={getToolTipTitle()}
      onClick={() => toggle()}
      active={enabled && isModeratorOrPresenter}
      disabled={pending || !isModeratorOrPresenter}
      data-testid="toolbarBlurScreenButton"
      id={ToolbarButtonIds.ShareScreen}
    >
      {enabled ? <ShareScreenOnIcon /> : <ShareScreenOffIcon />}
    </ToolbarButton>
  );
};

export default ShareScreenButton;
