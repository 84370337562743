// SPDX-FileCopyrightText: OpenTalk GmbH <mail@opentalk.eu>
//
// SPDX-License-Identifier: EUPL-1.2
import { useLocalParticipant, useMediaDeviceSelect } from '@livekit/components-react';
import { ListItemText, ThemeProvider, Typography, styled } from '@mui/material';
import { LocalAudioTrack, Track } from 'livekit-client';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ErrorIcon, MicOnIcon, WarningIcon } from '../../../assets/icons';
import { createOpenTalkTheme } from '../../../assets/themes/opentalk';
import { useFullscreenContext } from '../../../hooks/useFullscreenContext';
import { DeviceId } from '../../../modules/Media/MediaUtils';
import { useUserChoices } from '../../../provider/UserChoicesProvider';
import DeviceList from './DeviceList';
import { MenuSectionTitle, ToolbarMenu, ToolbarMenuProps } from './ToolbarMenuUtils';

const MultilineTypography = styled(Typography)({
  whiteSpace: 'pre-wrap',
});

interface AudioMenuProps extends ToolbarMenuProps {
  permissionDenied: boolean | null;
  microphoneEnabled: boolean;
}

const AudioMenu = ({ anchorEl, onClose, open, permissionDenied, microphoneEnabled }: AudioMenuProps) => {
  const { t } = useTranslation();

  const choices = useUserChoices();
  const [requestPermissions, setRequestPermissions] = useState(open);
  const [audioTrack, setAudioTrack] = useState<LocalAudioTrack | undefined>(undefined);
  const [activeDeviceId, setActiveDeviceId] = useState<string | undefined>(undefined);

  const { localParticipant } = useLocalParticipant();

  useEffect(() => {
    const createTrack = async () => {
      const deviceId = choices?.userChoices.audioDeviceId;
      try {
        const tracks = await localParticipant.createTracks({ audio: deviceId ? { deviceId } : true });
        const audioTrack = tracks.find((track) => track.kind === Track.Kind.Audio) as LocalAudioTrack | undefined;
        if (audioTrack) {
          setAudioTrack(audioTrack);
          const deviceId = await audioTrack.getDeviceId();
          if (deviceId) {
            choices?.saveAudioInputDeviceId(deviceId);
            setActiveDeviceId(deviceId);
          }
        }
      } catch (e) {
        console.log('AudioMenu - error: ', e);
      }
    };

    if ((microphoneEnabled || open) && !audioTrack) {
      createTrack();
    }

    if (open && !requestPermissions) {
      setRequestPermissions(true);
    }
  }, [microphoneEnabled, open, audioTrack, localParticipant, choices, requestPermissions]);

  const { devices } = useMediaDeviceSelect({
    kind: 'audioinput',
    requestPermissions,
  });

  const filteredDevices = useMemo(() => devices.filter((device) => device.deviceId !== ''), [devices]);
  const selectedDeviceId = useMemo(
    () => activeDeviceId || choices?.userChoices.audioDeviceId,
    [activeDeviceId, choices?.userChoices.audioDeviceId]
  );

  const fullscreenHandle = useFullscreenContext();

  useEffect(() => {
    if (choices?.userChoices.audioDeviceId === '' && filteredDevices[0]?.deviceId) {
      choices.saveAudioInputDeviceId(selectedDeviceId || filteredDevices[0].deviceId);
    }

    if (
      choices?.userChoices.audioDeviceId &&
      !filteredDevices.map((device) => device.deviceId).includes(choices?.userChoices.audioDeviceId) &&
      filteredDevices[0]?.deviceId
    ) {
      choices.saveAudioInputDeviceId(filteredDevices[0].deviceId);
    }
  }, [filteredDevices, choices, selectedDeviceId]);

  useEffect(() => {
    if (activeDeviceId === '' && devices.length > 0 && selectedDeviceId) {
      audioTrack?.setDeviceId(selectedDeviceId);
    }
  }, [activeDeviceId, devices, audioTrack, selectedDeviceId]);

  const handleClick = (deviceId: DeviceId) => {
    if (deviceId !== selectedDeviceId) {
      choices?.saveAudioInputDeviceId(deviceId);
      audioTrack?.setDeviceId(deviceId);
      setActiveDeviceId(deviceId);
    }
  };

  // Todo show spinner while we fetch the permissions?
  return (
    <ThemeProvider theme={createOpenTalkTheme()}>
      <ToolbarMenu
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: -4,
          horizontal: 'center',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={onClose}
        disablePortal={fullscreenHandle.active}
        id="audio-context-menu"
        aria-labelledby="audio-menu-title"
        role="listbox"
      >
        <MenuSectionTitle id="audio-menu-title" sx={{ pt: 1.5, pb: 1.5 }}>
          <MicOnIcon />
          {t('audiomenu-choose-input')}
        </MenuSectionTitle>

        {permissionDenied && (
          <MenuSectionTitle>
            <ErrorIcon />
            <MultilineTypography variant="body2">{t('device-permission-denied')}</MultilineTypography>
          </MenuSectionTitle>
        )}

        {/* // TODO: livekit -{devices === undefined || !mediaContext.hasAllAudioDetails ? ( */}
        {filteredDevices.length === 0 ? (
          <MenuSectionTitle>
            <WarningIcon />
            <ListItemText>{t('devicemenu-wait-for-permission')}</ListItemText>
          </MenuSectionTitle>
        ) : (
          <DeviceList
            devices={filteredDevices.sort((a, b) => a.label.localeCompare(b.label))}
            selectedDevice={selectedDeviceId as DeviceId | undefined}
            onClick={(deviceId: DeviceId) => handleClick(deviceId)}
            ariaLabelId="audio-menu-title"
          />
        )}
      </ToolbarMenu>
    </ThemeProvider>
  );
};

export default AudioMenu;
