// SPDX-FileCopyrightText: OpenTalk GmbH <mail@opentalk.eu>
//
// SPDX-License-Identifier: EUPL-1.2
import { isWeb } from '@livekit/components-core';
import { LiveKitRoom } from '@livekit/components-react';
import { styled } from '@mui/material';
import { XORCipher, selectIsAuthenticated } from '@opentalk/redux-oidc';
import { RoomId } from '@opentalk/rest-api-rtk-query';
import {
  DeviceUnsupportedError,
  ExternalE2EEKeyProvider,
  LogLevel,
  LoggerNames,
  RemoteParticipant,
  RemoteTrackPublication,
  Room,
  RoomEvent,
  RoomOptions,
  Track,
  VideoPresets,
  isE2EESupported,
  setLogLevel,
} from 'livekit-client';
import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { useGetRoomEventInfoQuery } from '../../api/rest';
import OpentalkError from '../../components/Error';
import LobbyView from '../../components/LobbyView';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { useInviteCode } from '../../hooks/useInviteCode';
import { selectBetaLivekitServerUrl } from '../../store/slices/configSlice';
import { selectLivekitUnavailable, setLivekitRoom } from '../../store/slices/livekitSlice';
import { ConnectionState, selectRoomConnectionState } from '../../store/slices/roomSlice';
import { selectLivekitAccessToken } from '../../store/slices/roomSlice';
import { pinnedParticipantIdSet, pinnedRemoteScreenshare, selectPinnedParticipantId } from '../../store/slices/uiSlice';
import { ParticipantId } from '../../types';
import getE2eWorker from './fragments/E2eWorker';
import RoomLoadingView from './fragments/RoomLoadingView';

const MeetingView = React.lazy(() => import('../../components/MeetingView'));
const WaitingView = React.lazy(() => import('../../components/WaitingView'));

const RoomContainer = styled(LiveKitRoom)(() => {
  return {
    display: 'contents',
  };
});

const RoomPage = () => {
  const { t } = useTranslation();

  const inviteCode = useInviteCode();
  const isAuthenticated = useAppSelector(selectIsAuthenticated);
  const connectionState: ConnectionState = useAppSelector(selectRoomConnectionState);
  const livekitAccessToken: string | undefined = useAppSelector(selectLivekitAccessToken);
  const serverUrl = useAppSelector(selectBetaLivekitServerUrl);
  const pinnedParticipantId = useAppSelector(selectPinnedParticipantId);
  const dispatch = useAppDispatch();
  const isLivekitUnavailable = useAppSelector(selectLivekitUnavailable);

  const { roomId } = useParams<'roomId'>() as {
    roomId: RoomId;
  };
  const { data: roomData } = useGetRoomEventInfoQuery({ id: roomId, inviteCode: inviteCode }, { skip: !roomId });

  const keyProvider = useMemo(() => new ExternalE2EEKeyProvider(), []);
  // TODO: livekit - re-evaluate
  const e2eePassphrase = XORCipher.handle(`${roomData?.id}${roomData?.title}`);
  const worker = getE2eWorker(e2eePassphrase);

  const e2eeEnabled = roomData?.e2EEncrytion && !!(e2eePassphrase && worker) && isE2EESupported();

  const roomOptions = useMemo((): RoomOptions => {
    return {
      publishDefaults: {
        /*
         * Up to two additional simulcast layers to publish in addition to the original
         * Track.
         * When left blank, it defaults to h180, h360.
         * If a SVC codec is used (VP9 or AV1), this field has no effect.
         * */
        // videoSimulcastLayers: [VideoPresets.h1080, VideoPresets.h720],
        red: !e2eeEnabled,
        simulcast: true,
      },
      dynacast: true,
      disconnectOnPageLeave: false,
      // Set it to `screen` to use the actual pixel density of the screen -> might significantly increase the bandwidth use
      // adaptiveStream: { pixelDensity: 'screen' },
      adaptiveStream: true,
      videoCaptureDefaults: {
        resolution: VideoPresets.h720.resolution,
      },
      e2ee: e2eeEnabled
        ? {
            keyProvider,
            worker,
          }
        : undefined,
    };
  }, [keyProvider, worker, e2eeEnabled]);

  const room = useMemo(() => {
    const roomInstance = new Room(roomOptions);
    setLivekitRoom(roomInstance);
    return roomInstance;
  }, [roomOptions]);

  if (e2eeEnabled && !room.isE2EEEnabled) {
    keyProvider.setKey(e2eePassphrase);
    room.setE2EEEnabled(true).catch((e) => {
      if (e instanceof DeviceUnsupportedError) {
        alert(
          `You're trying to join an encrypted meeting, but your browser does not support it. Please update it to the latest version and try again.`
        );
        console.error(e);
      }
    });
  }

  // TODO: livekit - relocate
  useEffect(() => {
    const onPageLeave = async () => {
      await room.disconnect();
    };

    if (isWeb()) {
      window.addEventListener('beforeunload', onPageLeave);
    }

    const handleTrackPublished = (pub: RemoteTrackPublication, participant: RemoteParticipant) => {
      if (pub.source === Track.Source.ScreenShare) {
        dispatch(pinnedRemoteScreenshare(participant.identity as ParticipantId));
      }
    };

    const handleTrackUnpublished = (pub: RemoteTrackPublication, participant: RemoteParticipant) => {
      if (pub.source === Track.Source.ScreenShare && participant.identity === pinnedParticipantId) {
        dispatch(pinnedParticipantIdSet(undefined));
      }
    };

    room.on(RoomEvent.TrackPublished, handleTrackPublished);
    room.on(RoomEvent.TrackUnpublished, handleTrackUnpublished);

    return () => {
      if (isWeb()) {
        window.removeEventListener('beforeunload', onPageLeave);
      }

      room.off(RoomEvent.TrackPublished, handleTrackPublished);
      room.off(RoomEvent.TrackUnpublished, handleTrackUnpublished);
    };
  }, [room, dispatch, pinnedParticipantId]);

  setLogLevel(LogLevel.silent, LoggerNames.E2EE);
  // setLogLevel(LogLevel.silent, LoggerNames.Room);
  // ---

  const renderRoom = useMemo(() => {
    if (!isAuthenticated && !inviteCode) {
      console.warn('meeting page - not logged in - redirect');
      return <LobbyView />;
    }
    switch (connectionState) {
      // Regular state machine flow
      case ConnectionState.Initial:
      case ConnectionState.Setup:
      case ConnectionState.FailedCredentials:
      case ConnectionState.Left:
        return <LobbyView />;
      case ConnectionState.Starting:
      case ConnectionState.Failed:
        return <RoomLoadingView />;
      case ConnectionState.Online:
      case ConnectionState.Leaving:
        return <MeetingView />;
      // Exception states
      case ConnectionState.ReadyToEnter:
      case ConnectionState.Waiting:
        return <WaitingView />;
      case ConnectionState.Blocked:
        return <RoomLoadingView />;
      default:
        console.error('room state unknown', connectionState);
        return <LobbyView />;
    }
  }, [connectionState, inviteCode, isAuthenticated]);

  if (isLivekitUnavailable) {
    return <OpentalkError title={t('error-livekit-unavailable')} />;
  }

  return (
    <>
      {room && (
        <RoomContainer room={room} token={livekitAccessToken} serverUrl={serverUrl} video={false} audio={false}>
          {renderRoom}
        </RoomContainer>
      )}
    </>
  );
};

export default RoomPage;
