// SPDX-FileCopyrightText: OpenTalk GmbH <mail@opentalk.eu>
//
// SPDX-License-Identifier: EUPL-1.2

let worker: Worker | null = null;

export default function getE2eWorker(e2eePassphrase: string): Worker | null {
  if (typeof window === 'undefined' || !e2eePassphrase) {
    return null;
  }

  if (!worker) {
    worker = new Worker(new URL('livekit-client/e2ee-worker', `file://${__filename}`));
  }

  return worker;
}
