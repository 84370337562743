// SPDX-FileCopyrightText: OpenTalk GmbH <mail@opentalk.eu>
//
// SPDX-License-Identifier: EUPL-1.2
import { Container, Stack, Typography, styled, useMediaQuery, useTheme } from '@mui/material';
import { RoomId } from '@opentalk/rest-api-rtk-query';
import { ReactNode, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { useGetRoomEventInfoQuery } from '../../api/rest';
import { BackIcon, CloseIcon, HelpIcon, Logo } from '../../assets/icons';
import arrowImage from '../../assets/images/arrow-illustration.png';
import { CircularIconButton } from '../../commonComponents';
import { useAppSelector } from '../../hooks';
import { useInviteCode } from '../../hooks/useInviteCode';
import useNavigateToHome from '../../hooks/useNavigateToHome';
import { useUserChoices } from '../../provider/UserChoicesProvider';
import { selectFeatures } from '../../store/slices/configSlice';
import { BreakoutRoomId } from '../../types';
import QuickStartPopover from '../QuickStartPopover';
import SpeedTestDialog from '../SpeedTestDialog';
import BottomContainer from './fragments/BottomContainer';
import EchoPlayBack from './fragments/EchoPlayback';
import VideoElement from './fragments/VideoElement';

const InnerContainer = styled('div')(() => ({
  position: 'relative',
  padding: 0,
  width: '100%',
  maxWidth: '1200px',

  //The blur should be part of the theme and handled globally
  backdropFilter: 'blur(100px)',
  WebkitBackdropFilter: 'blur(100px)',
  background: `rgba(0, 22, 35, 0.5) url(${arrowImage}) no-repeat 77% 67%`,
  backgroundSize: '10rem',
}));

const Header = styled('header')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  padding: theme.spacing(2, 2, 0),
  position: 'absolute',
  width: '100%',
  zIndex: 1,
}));

const UtilitiesContainer = styled(Stack)(({ theme }) => ({
  flexDirection: 'row',
  gap: theme.spacing(1),
}));

//Upscale and add margin to help icon, since the svg is just smaller than others and is offcenter.
//Should move into icon definition if it is also required elsewhere.
const AdjustedHelpIcon = styled(HelpIcon)(({ theme }) => ({
  transform: 'scale(1.3)',
  marginLeft: theme.typography.pxToRem(2),
}));

const BOTTOM_CONTAINER_Z_INDEX = 1;

const MobileBackButton = styled(CircularIconButton)(({ theme }) => ({
  position: 'absolute',
  zIndex: BOTTOM_CONTAINER_Z_INDEX,
  bottom: theme.spacing(2),
  left: theme.spacing(2),
}));

const MonitorContainer = styled('main')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  aspectRatio: '16/9',

  '& h1, p': {
    color: theme.palette.secondary.contrastText,
  },
}));

interface SelftestProps {
  children: ReactNode;
  actionButton?: ReactNode;
}

const SelfTest = ({ children, actionButton }: SelftestProps) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const choices = useUserChoices();
  const navigateToHome = useNavigateToHome();
  const inviteCode = useInviteCode();

  const { joinWithoutMedia } = useAppSelector(selectFeatures);

  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const { roomId } = useParams<'roomId' | 'breakoutRoomId'>() as {
    roomId: RoomId;
    breakoutRoomId?: BreakoutRoomId;
  };
  const { data: roomData } = useGetRoomEventInfoQuery({ id: roomId, inviteCode: inviteCode }, { skip: !roomId });

  const [isQuickStartPopoverOpen, setIsQuickStartPopoverOpen] = useState(false);
  const anchorElement = useRef<HTMLButtonElement>(null);

  const HeaderElement = () => {
    return (
      <Header>
        <Logo onClick={navigateToHome} />
        <UtilitiesContainer>
          <SpeedTestDialog />
          <CircularIconButton
            ref={anchorElement}
            onClick={() => setIsQuickStartPopoverOpen((value) => !value)}
            aria-label={isQuickStartPopoverOpen ? t('conference-quick-start-close') : t('conference-quick-start-open')}
          >
            {isQuickStartPopoverOpen ? <CloseIcon /> : <AdjustedHelpIcon />}
          </CircularIconButton>
        </UtilitiesContainer>
      </Header>
    );
  };

  return (
    <Container>
      <InnerContainer>
        <HeaderElement />

        <MonitorContainer>
          {choices?.userChoices.videoEnabled && choices?.userChoices.videoDeviceId !== '' ? (
            <VideoElement />
          ) : (
            <>
              {roomData?.title && (
                <Typography
                  variant="h2"
                  textAlign="center"
                  color={theme.palette.common.white}
                  marginBottom={theme.spacing(5)}
                  component="h1"
                >
                  {t('joinform-room-title', { title: roomData?.title })}
                </Typography>
              )}
              <Typography
                variant="h1"
                textAlign="center"
                fontSize="2.9rem"
                lineHeight="2.9rem"
                mb={2}
                component="h2"
                color={theme.palette.common.white}
              >
                {t('selftest-header')}
              </Typography>
              <Typography textAlign="center" fontSize="1.37rem">
                {joinWithoutMedia ? t('selftest-body-do-test') : t('selftest-body')}
              </Typography>
            </>
          )}
          {choices?.userChoices.audioEnabled && choices?.userChoices.audioDeviceId !== '' && <EchoPlayBack />}
        </MonitorContainer>

        <BottomContainer actionButton={actionButton}>{children}</BottomContainer>

        {isMobile && (
          <MobileBackButton aria-label={t('global-back')} onClick={navigateToHome}>
            <BackIcon />
          </MobileBackButton>
        )}

        <QuickStartPopover
          open={isQuickStartPopoverOpen}
          anchorEl={anchorElement.current}
          onClose={() => setIsQuickStartPopoverOpen(false)}
          variant="lobby"
        />
      </InnerContainer>
    </Container>
  );
};

export default SelfTest;
