// SPDX-FileCopyrightText: OpenTalk GmbH <mail@opentalk.eu>
//
// SPDX-License-Identifier: EUPL-1.2
import { usePreviewDevice } from '@livekit/components-react';
import { useCallback, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { notifications } from '../../../commonComponents';
import { EchoTest, EchoTestState } from '../../../modules/WebRTC/EchoTest';
import { useUserChoices } from '../../../provider/UserChoicesProvider';

const EchoPlayBack = () => {
  const { t } = useTranslation();
  const audioRef = useRef<HTMLAudioElement>(null);

  const choices = useUserChoices();
  const { localTrack: audioTrack, deviceError } = usePreviewDevice(
    choices?.userChoices.audioEnabled || false,
    choices?.userChoices.audioDeviceId || '',
    'audioinput'
  );

  useEffect(() => {
    if (deviceError !== null) {
      choices?.saveAudioInputEnabled(false);

      if (deviceError.name !== 'NotAllowedError') {
        console.error('error while publishing preview videotrack: ', deviceError);
      }
    }
  }, [deviceError, choices?.saveAudioInputEnabled]);

  const changeHandler = useCallback(
    (instance: EchoTest) => (echoTestState: EchoTestState) => {
      switch (echoTestState) {
        case 'connected':
          break;
        case 'streamUpdate':
          if (audioRef.current === null) {
            console.error('no audio element found.');
            return;
          }
          if (instance.outStream !== undefined) {
            audioRef.current.srcObject = instance.outStream;
          } else {
            console.error('no out stream - broken or called too early.');
            audioRef.current.srcObject = null;
          }
          break;
        case 'closed':
          if (audioRef.current !== null) {
            audioRef.current.srcObject = null;
          }
          break;
        default:
          console.error('unknown state change');
      }
    },
    []
  );

  useEffect(() => {
    if (!audioTrack) {
      return;
    }

    const audioTrackSettings = audioTrack.mediaStreamTrack.getSettings();

    if (!audioTrackSettings.echoCancellation) {
      console.warn('no support for echoCancellation', audioTrackSettings);
      notifications.warning(t('echotest-warn-no-echo-cancellation'), { persist: true });
    }
  }, [audioTrack, t]);

  useEffect(() => {
    if (!audioTrack) {
      return;
    }

    const echoTest = new EchoTest();
    const echoChangeHandler = changeHandler(echoTest);
    echoTest.addEventListener('stateChanged', echoChangeHandler);
    audioTrack.mediaStream &&
      echoTest.connect(audioTrack.mediaStream).catch((e) => {
        console.error('Failed to connect EchoTest', e);
      });

    return () => {
      echoTest.close();
      echoTest.removeEventListener('stateChanged', echoChangeHandler);
    };
  }, [audioTrack, changeHandler]);

  return (
    <audio ref={audioRef} autoPlay>
      <track kind="captions" />
    </audio>
  );
};

export default EchoPlayBack;
