// SPDX-FileCopyrightText: OpenTalk GmbH <mail@opentalk.eu>
//
// SPDX-License-Identifier: EUPL-1.2
import { usePreviewDevice } from '@livekit/components-react';
import { BackgroundBlur, VirtualBackground } from '@livekit/track-processors';
import { Grid, styled } from '@mui/material';
import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from '../../../hooks';
import { useUserChoices } from '../../../provider/UserChoicesProvider';
import { selectVideoBackgroundEffects } from '../../../store/slices/mediaSlice';
import { selectMirroredVideoEnabled } from '../../../store/slices/uiSlice';

const Container = styled(Grid)({
  position: 'relative',
  '&::before': {
    content: '""',
    top: 0,
    left: 0,
    display: 'block',
    height: 0,
    width: '0',
    paddingBottom: 'calc(9/16 * 100%)',
  },
});

const NoVideoText = styled('div')({
  position: 'absolute',
  transform: 'translateY(50%)',
  background: 'rgba(38, 48, 61, 0.95)',
  color: 'white',
  borderRadius: '0.312rem',
  padding: '1ex',
});

const Video = styled('video', {
  shouldForwardProp: (prop) => !['noRoundedCorners', 'mirroringEnabled'].includes(prop as string),
})<{ noRoundedCorners?: boolean; mirroringEnabled?: boolean }>(({ theme, noRoundedCorners, mirroringEnabled }) => ({
  position: 'absolute',
  width: 'inherit',
  height: 'inherit',
  maxWidth: '100%',
  maxHeight: '100%',
  borderRadius: noRoundedCorners ? 0 : theme.borderRadius.medium,
  transform: mirroringEnabled ? 'scale(-1,1)' : '',
}));

const VideoElement = () => {
  const { t } = useTranslation();
  const choices = useUserChoices();
  const videoRef = useRef<HTMLVideoElement>(null);
  const backgroundEffects = useAppSelector(selectVideoBackgroundEffects);

  const { localTrack: videoTrack, deviceError } = usePreviewDevice(
    choices?.userChoices.videoEnabled || false,
    choices?.userChoices.videoDeviceId || '',
    'videoinput'
  );

  useEffect(() => {
    const handleBackgroundEffect = async () => {
      const videoProcessor = videoTrack?.getProcessor();
      const isBlurred = backgroundEffects.style === 'blur';
      if (isBlurred && videoProcessor === undefined) {
        await videoTrack?.setProcessor(BackgroundBlur(10));
      } else if (!isBlurred && backgroundEffects.imageUrl) {
        await videoTrack?.setProcessor(VirtualBackground(backgroundEffects.imageUrl));
      } else if (!isBlurred && backgroundEffects.imageUrl === undefined && videoProcessor?.name) {
        await videoTrack?.stopProcessor();
      }
    };

    handleBackgroundEffect();

    return () => {
      videoTrack?.stopProcessor();
    };
  }, [videoTrack?.setProcessor, videoTrack?.getProcessor, videoTrack?.stopProcessor, backgroundEffects]);

  const mirroredVideoEnabled = useAppSelector(selectMirroredVideoEnabled);

  const isVideoMissing = choices?.userChoices.videoEnabled && videoTrack?.isMuted;

  useEffect(() => {
    if (deviceError) {
      choices?.saveVideoInputEnabled(false);
      if (deviceError.name !== 'NotAllowedError') {
        console.error('error while publishing preview video track: ', deviceError);
      }
    }
  }, [deviceError, choices]);

  useEffect(() => {
    if (videoRef.current && videoTrack) {
      videoTrack.unmute();
      videoTrack.attach(videoRef.current);
    }
    return () => {
      videoTrack?.detach();
    };
  }, [videoTrack]);

  useEffect(() => {
    if (isVideoMissing) {
      console.warn('Video is enabled but video tracks are closed');
    }
  }, [isVideoMissing]);

  return (
    <Container container justifyContent="center" alignItems="center" flexDirection="column">
      <Video ref={videoRef} noRoundedCorners mirroringEnabled={mirroredVideoEnabled} />
      {isVideoMissing && <NoVideoText>{t('localvideo-no-device')}</NoVideoText>}
    </Container>
  );
};

export default VideoElement;
